export const firebaseConfig = {
  apiKey: "AIzaSyDTRFjs0-W2bgTiAR0p87M90Y5Xw5aFOaY",
  authDomain: "dashboard-40ff6.firebaseapp.com",
  databaseURL: "https://dashboard-40ff6-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dashboard-40ff6",
  storageBucket: "dashboard-40ff6.appspot.com",
  messagingSenderId: "152747048843",
  appId: "1:152747048843:web:2634ef41563abc9afff98e",
  measurementId: "G-RGSWK89J3L",
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
